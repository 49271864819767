.c-unsubscribe {
    background-color: $primary;
    color: #FFF;

    .typography {
        h1 {
            color: #FFF;
            margin-bottom: 0.8em;
        }
    }

    &__inner {
        padding: 30px 10px 45px;

        @media (min-width: $md) {
            padding: 50px 30px 65px;
        }
    }

    &__email {
        .h3 {
            margin-bottom: 0;
        }
    }
}
