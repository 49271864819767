.l-container {
    max-width: $max-width;
    width: 100%;
    margin: 0 auto;
}

.l-pinch {
    @media (min-width: $md) {
        $pinchWidth: unit(100 / $columns * 10, %);

        width: $pinchWidth;
        margin: 0 auto;
    }

    &--large {
        @media (min-width: $sm) {
            $pinchWidth: unit(100 / $columns * 10, %);

            width: $pinchWidth;
            margin: 0 auto;
        }

        @media (min-width: $md) {
            $pinchWidth: unit(100 / $columns * 8, %);

            width: $pinchWidth;
        }
    }
}

.l-content-row {
    margin-bottom: 60px;

    @media (min-width: $md) {
        margin-bottom: 100px;
    }
}

.l-right {
    display: flex;
    justify-content: flex-end;
}

.l-inner-content-row {
    margin-top: 30px;

    &--large {
        padding-top: 30px;

        @media (min-width: $md) {
            padding-top: 100px;
        }
    }
}

.l-full {
    padding-left: $gutter;
    padding-right: $gutter;

    &--large {
        @media (min-width: $lg) {
            padding-left: $gutter;
            padding-right: $gutter;
        }
    }
}

.l-thin {
    padding-left: $gutter;
    padding-right: $gutter;
    width: 100%;

    @media (min-width: $md) {
        width: unit(100 / $columns * 7, %);
    }
}

.l-no-grow {
    > * {
        flex-grow: 0 !important;
    }
}

.l-no-gutter {
    > * {
        margin: 0 !important;
    }
}

.l-center {
    display: flex;
    justify-content: center;
}

.l-vertical-center {
    display: flex;
    align-items: center;
}

.l-space-between {
    padding-left: $gutter;
    padding-right: $gutter;

    @media (min-width: $md) {
        display: flex;
        justify-content: space-between;

        > * {
            &:first-child {
                margin-right: $gutter;
            }

            &:not(:first-child):last-child {
                margin-left: $gutter;
            }
        }
    }
}

.l-halves {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $sm) {
        flex-wrap: nowrap;
    }

    > * {
        flex: 1 1 100%;
        flex-basis: calc(100% - #{$full-gutter});
        width: calc(100% - #{$full-gutter});
        margin-left: $gutter;
        margin-right: $gutter;

        @media (min-width: $sm) {
            flex: 1 1 50%;
            flex-basis: calc(50% - #{$full-gutter});
            width: calc(50% - #{$full-gutter});
        }
    }
}

.l-two-thirds {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    > * {
        flex-basis: calc(100% - #{$full-gutter});
        margin-left: $gutter;
        margin-right: $gutter;

        @media (min-width: $md) {
            &:first-child {
                flex: 1 1 66.666%;
                flex-basis: calc(66.666% - #{$full-gutter});
                width: calc(66.666% - #{$full-gutter});
            }

            &:last-child:not(:first-child) {
                flex: 1 1 33.333%;
                flex-basis: calc(33.333% - #{$full-gutter});
                width: calc(33.333% - #{$full-gutter});
            }
        }
    }
}

.l-third-two-thirds {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    > * {
        flex-basis: calc(100% - #{$full-gutter});
        margin-left: $gutter;
        margin-right: $gutter;

        @media (min-width: $sm) {
            flex: 1 1 50%;
            flex-basis: calc(50% - #{$full-gutter});
            width: calc(50% - #{$full-gutter});
        }

        @media (min-width: $md) {
            &:first-child {
                flex: 1 1 33.333%;
                flex-basis: calc(33.333% - #{$full-gutter});
                width: calc(33.333% - #{$full-gutter});
            }

            &:last-child:not(:first-child) {
                flex: 1 1 66.666%;
                flex-basis: calc(66.666% - #{$full-gutter});
                width: calc(66.666% - #{$full-gutter});
            }
        }
    }
}

.l-three-quarters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    > * {
        flex-basis: calc(100% - #{$full-gutter});
        margin-left: $gutter;
        margin-right: $gutter;

        @media (min-width: $sm) {
            flex: 1 1 50%;
            flex-basis: calc(50% - #{$full-gutter});
            width: calc(50% - #{$full-gutter});
        }

        @media (min-width: $md) {
            &:first-child {
                flex: 1 1 75%;
                flex-basis: calc(75% - #{$full-gutter});
                width: calc(75% - #{$full-gutter});
            }

            &:last-child:not(:first-child) {
                flex: 1 1 25%;
                flex-basis: calc(25% - #{$full-gutter});
                width: calc(25% - #{$full-gutter});
            }
        }
    }
}

.l-quarters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    > * {
        flex-basis: calc(100% - #{$full-gutter});
        margin-left: $gutter;
        margin-right: $gutter;

        @media (min-width: $sm) {
            flex: 1 1 50%;
            flex-basis: calc(50% - #{$full-gutter});
            width: calc(50% - #{$full-gutter});
        }

        @media (min-width: $md) {
            flex: 1 1 25%;
            flex-basis: calc(25% - #{$full-gutter});
            width: calc(25% - #{$full-gutter});
        }
    }
}

.l-thirds {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    > * {
        flex-basis: calc(100% - #{$full-gutter});
        margin-left: $gutter;
        margin-right: $gutter;

        @media (min-width: $sm) {
            flex: 1 1 33.333%;
            flex-basis: calc(33.333% - #{$full-gutter});
            width: calc(33.333% - #{$full-gutter});
        }
    }
}

@supports (display: grid) {
    .l-thirds {
        display: grid;
        gap: $full-gutter;

        @media (min-width: $sm) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        > * {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.l-7-1-4,
.l-4-1-7 {
    display: flex;
    flex-wrap: wrap;

    > * {
        margin-left: $gutter;
        margin-right: $gutter;
        flex: 1 1 100%;
        flex-basis: calc(100% - #{$full-gutter});
        width: calc(100% - #{$full-gutter});

        @media (min-width: $sm) {
            flex: 1 1 50%;
            flex-basis: calc(50% - #{$full-gutter});
            width: calc(50% - #{$full-gutter});
        }

        @media (min-width: $md) {
            &:nth-child(2n + 1) {
                flex: 1 1 #{$sevenColSpace};
                flex-basis: calc(#{$sevenColSpace} - #{$full-gutter});
                width: calc(#{$sevenColSpace} - #{$full-gutter});
            }

            &:nth-child(2n) {
                flex: 1 1 #{$fourColSpace};
                flex-basis: calc(#{$fourColSpace} - #{$full-gutter + $gutter});
                width: calc(#{$fourColSpace} - #{$full-gutter + $gutter});
                margin-left: calc(#{$oneColSpace} + #{$gutter});
            }
        }
    }
}

.l-4-1-7 {
    > * {
        @media (min-width: $md) {
            &:nth-child(2n) {
                flex: 1 1 #{$sevenColSpace};
                flex-basis: calc(#{$sevenColSpace} - #{$full-gutter});
                width: calc(#{$sevenColSpace} - #{$full-gutter});
                margin-left: $gutter;
            }

            &:nth-child(2n + 1) {
                flex: 1 1 #{$fourColSpace};
                flex-basis: calc(#{$fourColSpace} - #{$full-gutter + $gutter});
                width: calc(#{$fourColSpace} - #{$full-gutter + $gutter});
                margin-right: calc(#{$oneColSpace} + #{$gutter});
            }
        }
    }
}
