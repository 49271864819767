.o-square{
    display: block;
    position: relative;
    max-width: 333px;
    background-color: $grey;
    backface-visibility: hidden;
    z-index: 1;

    &:after{
        content: '';
        display: block;
        width: 100%;
        padding-bottom: ratio(333, 300);
    }

    &--white{
        background-color: #fff;
    }

    &--primary{
        background-color: $primary;
        color: #fff;

        h1,
        h2,
        h3,
        .h1,
        .h2,
        .h3{
            color: #fff;
        }
    }

    &--black{
        background-color: $black;
        color: #fff;
    }

    &--full-width,
    &--stretched{
        min-height: 300px;
        max-width: none;

        &:after{
            display: none;
        }
    }

    &--content-image{
        max-width: none;
        max-height: none;

        @media (min-width: $md){
            min-height: 300px;
        }

        &:after{
            display: none;
        }
    }

    &--large{
        max-width: 456px;
        min-height: 456px;

        &:after{
            display: none;
        }
    }

    &--small{
        width: 200px;

        &:after{
            padding-bottom: 100%;
        }
    }

    &--medium{
        width: 290px;

        &:after{
            padding-bottom: 100%;
        }
    }

    &__container{
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 15px;
        justify-content: space-around;
        @media (min-width: $md){
            padding: 15px 30px;
        }
    }

    &--large &__container{
        @media (min-width: $md){
            padding: 50px;
        }
    }

    &--small &__container{
        padding: 25px 20px;
        align-items: flex-start;
    }

    &--full-width &__container{
        padding: 45px 30px 85px;

        @media (min-width: $sm){
            padding-right: 105px;
            padding-bottom: 15px;
            padding-top: 15px;
        }

        @media (min-width: $md){
            padding-right: 250px;
            padding-left: 45px;
        }
    }

    &--stretched &__container{
        padding: 40px 40px 110px;
        position: relative;
    }

    &--description &__container{
        padding: 40px;
        align-items: flex-start;
    }

    &--content-image &__container{
        position: relative;
        flex-wrap: wrap;
        padding: 0 30px;

        @media (min-width: $md){
            position: absolute;
            padding: 0 45px;
            flex-wrap: nowrap;
        }
    }

    &__content{
        text-align: center;

        p:last-child{
            margin-bottom: 0;
        }
    }

    &--image &__content,
    &--small &__content,
    &--full-width &__content,
    &--stretched &__content,
    &--description &__content,
    &--content-image &__content{
        text-align: left;
    }

    &--content-image &__content{
        flex: 1 1 60%;
        flex-basis: calc(60% - 15px);
        width: calc(60% - 15px);
        margin-right: 15px;
        padding: 30px 0;

        @media (min-width: $md){
            padding: 0;
        }
    }

    &--content-image &__content-image{
        flex: 1 1 100%;
        flex-basis: calc(100% - 15px);
        width: calc(100% - 15px);
        margin-left: 15px;
        align-self: flex-end;
        align-items: flex-end;
        justify-content: center;
        display: flex;

        @media (min-width: $md){
            flex: 1 1 40%;
            flex-basis: calc(40% - 15px);
            width: calc(40% - 15px);
        }

        > *{
            width: 75%;

            @media (min-width: $xs){
                width: 60%;
            }

            @media (min-width: $sm){
                width: 40%;
            }

            @media (min-width: $md){
                width: 100%;
            }
        }
    }

    &--image &__content{
        z-index: 1;
        padding-top: 75%;
        padding-right: 50px;
    }

    &__title{
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 25px !important;
        color: $primary;
    }

    &--primary &__title{
        color: #fff;
    }

    &__image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img{
            position: absolute;
            top: -35%;
            left: -5%;
            width: 100%;
            width: 120%;
            max-width: none;
        }
    }

    &__logo{
        position: absolute;
        top: 15px;
        left: 15px;
        bottom: 15px;
        right: 15px;
        overflow: hidden;

        > *{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__arrow-btn{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 60px;
        height: 60px;
        transform: translate(-50%, 50%);

        @media (min-width: $md){
            transform: translate(50%, -50%);
        }

        &--bottom-right{
            transform: translate(-50%, 50%);
        }

        &--inside{
            transform: none;
        }
    }

    &--full-width &__arrow-btn{
        @media (max-width: $sm-max){
            transform: translate(-50%, -50%);
        }
    }

    &:not(div):hover .o-btn__icon,
    &:not(div):focus .o-btn__icon{
        transition-duration: $userActive;
        transform: translateX(40%);
    }

    &:not(div):hover .o-btn__icon--left,
    &:not(div):hover .o-btn__icon--left{
        transition-duration: $userActive;
        transform: translateX(-40%);
    }

    &:not(div):hover .o-btn__icon--up,
    &:not(div):hover .o-btn__icon--up{
        transition-duration: $userActive;
        transform: translateY(-40%);
    }

    &:not(div):hover .o-btn__icon--down,
    &:not(div):hover .o-btn__icon--down{
        transition-duration: $userActive;
        transform: translateY(40%);
    }

    &__hover{
        &-container{
            position: relative;

            @media (min-width: $md){
                overflow: hidden;
            }

            > *{
                &:first-child{
                    width: 100%;
                    height: 300px;
                    min-height: 0;

                    @media (min-width: $xs){
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: translateY(-120px);
                        z-index: 2;
                        width: 320px;
                    }

                    @media (min-width: $sm){
                        right: auto;
                        left: 120px;
                    }

                    @media (min-width: $md){
                        position: relative;
                        left: 0;
                        width: auto;
                        height: auto;
                        min-height: 456px;
                        transform: none;
                        z-index: 0;
                    }
                }
            }
        }

        &-item{
            @media (min-width: $xs){
                transform: translateY(120px);
                margin-bottom: 120px;
            }

            @media (min-width: $sm){
                transform: translate(240px, 120px);
            }

            @media (min-width: $md){
                margin-bottom: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: translateY(100%);
                transition: transform 550ms $easeOut;
            }
        }

        &-container:hover &-item,
        &-container:focus &-item{
            @media (min-width: $md){
                transform: none;
            }
        }
    }
}
