.c-property-slider{
	&__viewport{
		position: relative;
		overflow: hidden;
	}

	&__track{
		display: flex;
	}

	&__main{
		padding-left: $gutter;
		padding-right: $gutter;
		margin-bottom: 30px;
	}

	&__nav{
		@media (max-width: $sm-max){
			display: none;
		}
	}

	&__main &__track{
		> *{
			flex: 1 0 100%;
			width: 100%;
            position: relative;
            overflow: hidden;
		}
	}

	&__nav &__track{
		> *{
			flex: 1 0 16.666%;
			flex-basis: calc(16.666% - #{$full-gutter});
			width: calc(16.666% - #{$full-gutter});
			margin-left: $gutter;
			margin-right: $gutter;
		}
	}

	&__arrows{
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 1;
	}

	&__image{
		position: relative;
		height: 0;

		> *{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: auto;
			transform: translate(-50%, -50%);
		}
	}

	&__main &__image{
		padding-bottom: ratio(1140, 617);
	}

	&__nav &__image{
		padding-bottom: ratio(165, 100);
	}

	&__nav &__slide{
		cursor: pointer;

		> *{
			pointer-events: none;
		}
	}

    &__tag-holder {
        display: block;
        width: 1px;
        height: 1px;
        position: absolute;
        left: 50px;
        top: 50px;
        z-index: 100;
        //background-color: yellow;

        &-in {
            position: relative;
        }
    }

    &__tag {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        z-index: 20;
        transform: rotate(-45deg);
        background-color: $red;
        position: absolute;
        top: -25px;
        left: -260px;
        width: 500px;
        height: 30px;

        @media (min-width: $xxs){
            top: -30px;
            left: -260px;
            width: 500px;
            height: 40px;
            //background-color: pink;
        }
        @media (min-width: $xs){
            top: -20px;
            left: -240px;
            height: 60px;
            //background-color: purple;
        }
        @media (min-width: $sm){
            top: -10px;
            left: -220px;
            height: 80px;
            //background-color: rosybrown;
        }
        @media (min-width: $md){
            top: 0;
            left: -200px;
            height: 100px;
            //background-color: yellow;
        }

        span {
            text-transform: uppercase;
            color: #fff;
            font-size: 13px;
            font-weight: bold;

            @media (min-width: $xxs){
                font-size: 14px;
            }
            @media (min-width: $xs){
                font-size: 20px;
            }
            @media (min-width: $sm){
                font-size: 26px;
            }
            @media (min-width: $md){
                font-size: 28px;
            }
        }
    }
}

@supports (object-fit: cover){
	.c-property-slider{
		&__image{
			> *{
				left: 0;
				top: 0;
				height: 100%;
				transform: none;
				object-fit: cover;
			}
		}
	}
}
