.l-vspace{
	margin-bottom: 60px;

	@media (min-width: $md){
		margin-bottom: 120px;
	}

	&--text{
		margin-bottom: 50px;

		@media (min-width: $md){
			margin-bottom: 100px;
		}
	}

	&--large{
		margin-bottom: 70px;

		@media (min-width: $md){
			margin-bottom: 140px;
		}
	}

	&--x-large{
		margin-bottom: 90px;

		@media (min-width: $md){
			margin-bottom: 180px;
		}
	}

	&--small{
		margin-bottom: 45px;

		@media (min-width: $md){
			margin-bottom: 75px;
		}
	}

	&--x-small{
		margin-bottom: 30px;

		@media (min-width: $md){
			margin-bottom: 50px;
		}
	}

	&--overlap{
		margin-bottom: 30px;

		@media (min-width: $md){
			margin-bottom: -30px;
		}
	}
}
