//====================
// Typography
//====================

// Headings
//===============

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $header-weight;
    line-height: $p-line-height;
    margin-bottom: $type-margin-bottom;
    font-size: $base-font-size;
}

p {
    margin-bottom: $p-type-margin-bottom;
}

#{$typegraphyClass} h1,
.h1 {
    line-height: 1.2;

    @include css-lock(3.2, 4.8, 560, 1200);
}

#{$typegraphyClass} h2,
.h2,
#{$typegraphyClass} h3,
.h3 {
    font-size: 1.4rem;
    text-transform: uppercase;
    line-height: $p-line-height;
    font-weight: 700;
}
#{$typegraphyClass} h4,
.h4 {
    font-size: 1.4rem;
    line-height: $p-line-height;
}
#{$typegraphyClass} h5,
.h5 {
    font-size: 1.2rem;
    line-height: $p-line-height;
}
.type-new h2,
.type-new .h2 {
    font-size: 2.6rem;
    font-family: $header-font-family;
    line-height: 1.2;
    font-weight: 400;
    text-transform: none;

    @media (min-width: $sm) {
        font-size: 4rem;
    }
}

#{$typegraphyClass} h1,
.h1 {
    font-family: $header-font-family;
}

#{$typegraphyClass} h1,
.h1,
#{$typegraphyClass} h2,
.h2,
#{$typegraphyClass} h3,
.h3,
#{$typegraphyClass} h4,
.h4,
#{$typegraphyClass} h5,
.h5,
#{$typegraphyClass} h6,
.h6 {
    color: currentColor;
    margin-bottom: $type-margin-bottom;

    &:not(:first-child) {
        margin-top: $type-margin-top;
    }

    strong {
        font-weight: 700;
    }
}

#{$typegraphyClass} h1,
#{$typegraphyClass} h2,
#{$typegraphyClass} h3,
#{$typegraphyClass} h4,
#{$typegraphyClass} h5,
#{$typegraphyClass} h6 {
    color: $primary;
}

#{$typegraphyClass} h1,
.h1 {
    margin-bottom: 0.5em;
}

#{$typegraphyClass} h1,
.h1,
#{$typegraphyClass} h2,
.h2,
#{$typegraphyClass} h3,
.h3,
#{$typegraphyClass} h4,
.h4,
#{$typegraphyClass} h5,
.h5,
#{$typegraphyClass} h6,
.h6 {
    & + h1:not(:first-child),
    & + .h1:not(:first-child),
    & + h2:not(:first-child),
    & + .h2:not(:first-child),
    & + h3:not(:first-child),
    & + .h3:not(:first-child),
    & + h4:not(:first-child),
    & + .h4:not(:first-child),
    & + h5:not(:first-child),
    & + .h5:not(:first-child),
    & + h6:not(:first-child),
    & + .h6:not(:first-child) {
        margin-top: $type-margin-neg-top;
    }
}

// inline elements
//===============
table,
pre {
    line-height: line-height-scale();
    margin-bottom: $p-type-margin-bottom;
}

strong,
b {
    font-weight: $strong-weight;
}
