.c-accreditations {
  &__title {
    color: $primary;
    font-size: 1.4em;
    text-align: center;

    @media (min-width: $sm) {
      padding: 0 20px;
      text-align: left;
    }
  }

  &__images-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    &-image {
      padding: 15px;
      flex: 1 0 calc(50% - 30px);

      @media (min-width: $sm) {
        flex-basis: calc(50% - 60px);
        padding: 30px;
      }

      img {
        display: block;
        margin: auto;
      }
    }
  }
}

