.c-echos {
    position: relative;

    figure > :first-child {
        margin-bottom: 0;
    }

    &__container {
        position: relative;
        z-index: 1;
    }

    &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        @media (min-width: $sm) {
            margin-bottom: 75px;
        }
    }

    &__image-wrap {
        position: relative;
    }

    &__item:nth-child(2) &__image-wrap,
    &__item:first-child &__image-wrap {
        &:before {
            content: "";
            position: absolute;
            top: 30px;
            bottom: 30px;
            left: 50%;
            width: 100vw;
            background-color: $primary;
            transform: translateX(-50%);

            @media (min-width: $sm) {
                top: 68px;
                bottom: 68px;
            }
        }
    }

    &__item:nth-child(2) &__image-wrap:before {
        display: none;

        @media (min-width: $sm) {
            display: block;
        }
    }

    &__item:first-child &__image-wrap:before {
        @media (min-width: $sm) {
            display: none;
        }
    }

    &__image {
        display: block;
        position: relative;
        margin: 0 auto;
        min-width: 190px;
        width: 60%;
        z-index: 1;

        @media (min-width: $sm) {
            margin-bottom: 35px !important;
            max-width: 300px;
            width: 100%;
        }

        &:before {
            content: "";
            display: block;
            padding-bottom: ratio(300, 430);
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__description {
        position: relative;
        background-color: $grey;
        text-align: center;
        padding: 60px 20px 60px;
        margin-bottom: 30px;
        margin-top: -30px;
        max-width: none;
        flex-grow: 1;

        @media (min-width: $sm) {
            padding: 30px 20px 60px;
            margin-top: 0;
        }

        h2 {
            margin-bottom: 0;
        }

        .o-btn {
            position: absolute;
            margin-top: 0;
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__load-more {
        text-align: center;
        padding: 30px;
    }
}
