img{
	border: none;
}

.typography {
	img[style*="height"] {
		height: auto !important;
	}
}

