//====================
//	colours
//====================

//	colour pallete
//====================
//need to be bright colour
$primary: #051d4c;
$grey: #eee;
$black: #000;
$red: #ed1c24;
$primary-dark: #000033;

// named colours
//====================
$text-color: $black;
$header-color: $black;
$body-background-color: #fff;
