.c-opened-form {
  &__text-container {
    margin: 15px 15px 50px;
  }

  &__text {
    @media (min-width: 1024px) {
      width: 75%;
    }
  }
}

