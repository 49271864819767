.c-pagination {

    &__container {
        display: flex;
        align-items: center;
        margin: 0 15px;
        flex-direction: column;

        @media (min-width: $sm) {
            flex-direction: row;
        }
    }


    &__links {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: center;

        @media (min-width: $sm) {
            margin-right: 50px;
            align-items: end;
            flex-direction: row;
        }
    }

    &__links-list {
        display: flex;

        >* {
            margin: 0 5px;

            >span,
            >a:hover {
                border: 1px solid grey;
                padding: 0px 9px;
                display: inline-block;
                // border-radius: 30px;
                background-color: unset;
                color: Black;
                display: flex;
            }
        }
    }

    &__per-page {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;

        @media (min-width: $sm) {
            margin-top: 0;
            flex-direction: row;
        }
    }

    &__per-page-label {
        
        @media (min-width: $sm) {
            margin-right: 10px;
        }
    }
}