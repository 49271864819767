.c-tenant-login {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $primary;
    color: #fff;
    text-decoration: none;
    padding: 9px 25px;
    text-transform: uppercase;
    z-index: 20;
    outline: none;
    overflow: hidden;
    text-align: right;
    width: 100%;

    @media (min-width: $md) {
        right: 15px;
        padding: 15px 25px;
        text-align: left;
        width: auto;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #fff;
        z-index: -1;
        transform: translateY(102%);
        transition: transform $userInactive $easeOut;
        backface-visibility: hidden;
        pointer-events: none;
    }

    &:hover:before,
    &:focus:before {
        transition-duration: $userActive;
        transform: none;
    }
}
