.c-story-list{
    overflow: hidden;

    &__container{
        > *{
            &:not(:last-child){
                margin-bottom: 100px;
            }
        }
    }

    &__item{
        display: flex;
        flex-wrap: wrap;

        > * {
            margin: 10px $gutter;
            flex: 1 1 100%;
            flex-basis: calc(100% - #{$full-gutter});
            width: calc(100% - #{$full-gutter});

            @media (min-width: $sm){
                &:first-child {
                    flex: 1 1 33.333%;
                    flex-basis: calc(33.333% - #{$full-gutter});
                    width: calc(33.333% - #{$full-gutter});
                }

                &:last-child {
                    flex: 1 1 66.666%;
                    flex-basis: calc(66.666% - #{$full-gutter});
                    width: calc(66.666% - #{$full-gutter});
                }
            }
        }

        &--reverse {
            flex-direction: row-reverse;
        }
    }

    &__btn{
        opacity: 0;
        transform: translateY(20px);
        transition: opacity $userScrollIn $easeOut 600ms, transform $userScrollIn $easeOut 600ms;
    }

    .scroll-active &__btn{
        opacity: 1;
        transform: none;
    }

    &__item:nth-child(even){
        color: #fff;

        h1,
        h2,
        h3,
        h4,
        h5,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5{
            color: #fff;
        }
    }

    &__item:nth-child(even) &__content{
        position: relative;

        &:after{
            content: '';
            position: absolute;
            top: calc(50% - 13px);
            left: 50%;
            width: 150vw;
            min-width: 470px;
            min-height: 190px;
            height: calc(100% + 50px);
            transform: translate(-50%, -50%);
            background-color: $primary;
            z-index: -1;
        }
    }

    &__item:nth-child(even) > *:nth-child(1){
        z-index: 2;
    }

    &__item:nth-child(even) > *:nth-child(2){
        transition: opacity $userScrollIn $easeOut, transform $userScrollIn $easeOut;
    }

    &__item:nth-child(even) > *:nth-child(3){
        transition: opacity $userScrollIn $easeOut 300ms, transform $userScrollIn $easeOut 300ms;
    }

    &__item:nth-child(even) &__btn{
        transition: opacity $userScrollIn $easeOut 450ms, transform $userScrollIn $easeOut 450ms;
    }

    &__image {
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            padding-bottom: ratio(16, 12);
        }

        img{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -40%);
        }
    }

    &__content-wrap {
        display: flex;
        flex-direction: column;
    }

    &__btn {
        margin-top: auto;
    }
}

@supports (object-fit: cover) {
    .c-story-list {
        &__image {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
                object-position: center 15%;
            }
        }
    }
}

@supports (display: grid) {
    .c-story-list {
        &__item{
            display: grid;
            grid-template-columns: 1fr;
            gap: 30px;
            padding: 0 $gutter;

            @media (min-width: $sm) {
                grid-template-columns: 1fr 2fr;
            }

            &--reverse {
                @media (min-width: $sm) {
                    grid-template-columns: 2fr 1fr;

                    > *:first-child {
                        order: 1;
                    }
                }
            }

            > * {
                width: 100%;
                margin: 0;

                @media (min-width: $sm){
                    &:first-child,
                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }
    }
}
