.l-team {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    .o-btn__icon{
        margin-right: 60px;
    }
    > * {
        flex: 1 0 290px;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: $sm) {
            margin-bottom: 50px;
            flex: 1 0 360px;
        }

        &:nth-child(6n + 1),
        &:nth-child(6n + 2),
        &:nth-child(6n + 4),
        &:nth-child(6n) {
            min-width: 290px;

            @media (min-width: $sm) {
                min-width: 360px;
            }
        }

        &:nth-child(6n + 3),
        &:nth-child(6n + 5) {
            min-width: 290px;
        }

        @media (min-width: $sm) {
            &:nth-child(6n + 1) {
                top: 20px;

                @media (min-width: $md) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            &:nth-child(6n + 2) {
                align-self: flex-end;
                left: 20px;

                @media (min-width: $md) {
                    left: 10px;
                    margin-right: 40px;
                }
            }

            &:nth-child(6n + 3) {
                left: 10px;

                @media (min-width: $md) {
                    left: -4px;
                    margin-left: auto;
                    margin-right: 0;
                }
            }

            &:nth-child(6n + 4) {
                top: 40px;
                left: 28px;
            }

            &:nth-child(6n + 5) {
                margin-left: 60px;

                @media (min-width: $md) {
                    left: 45px;
                    margin-left: 20px;
                    margin-right: 40px;
                }
            }

            &:nth-child(6n) {
                top: 20px;
                left: 20px;

                @media (min-width: $md) {
                    margin-left: auto;
                    left: 0;
                }
            }
        
        }
    }
}

@supports (display: grid) {
    .l-team {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
        padding-left: $gutter;
        padding-right: $gutter;
        grid-row-gap: 50px;

        @media (max-width: 1170px) {
            max-width: 100%;
        }

        @media (min-width: $sm) {
            grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
        }

        > * {
            width: auto;

            @media (min-width: $md) {
                &:nth-child(6n + 5) {
                    margin-left: 0;
                }

                &:nth-child(6n) {
                    left: 15px;
                    margin-left: 0;
                }
            }
        }
    }
}
