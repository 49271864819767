.c-property-short {
    &__header {
        &-text {
            margin-bottom: 10px !important;
        }

        &-sub-text {
            margin-bottom: 50px !important;
        }
    }

    &__item + &__item {
        margin-top: 30px;

        @media (min-width: $md) {
            margin-top: 0;
        }
    }

    &__image {
        display: block;
        position: relative;
        overflow: hidden;
        margin: 0 30px;

        &:after {
            content: "";
            display: block;
            padding-bottom: ratio(458, 246);
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__content {
        position: relative;
        padding: 30px;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            width: 100%;
            height: calc(100% + 15px);
            top: -15px;
            left: 0;
            background-color: $grey;
        }
    }

    &__more-btn {
        margin-top: 20px;
        text-align: right;

        a {
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
        }
    }
}

@supports (object-fit: cover) {
    .c-property-short {
        &__image {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}
