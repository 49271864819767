.c-footer{
	position: relative;
	background-color: $black;
	color: #fff;
	z-index: 1;
	backface-visibility: hidden;
	background-image: url('/images/footer-map.png');
	background-size: cover;
	background-position: center center;

	a{
		color: currentColor;

		&:hover,
		&:focus{
			color: $primary;
		}
	}

	&__social-row{
		display: flex;
		justify-content: flex-end;

		> *{
			&:not(:last-child){
				margin-right: 9px;
			}
		}

		a{
			display: inline-block;
			width: 38px;
			height: 38px;
			padding: 3px;
		}

		svg{
			width: 32px;
			height: 32px;
			fill: currentColor;
		}
	}

	&__location{
		display: flex;
		justify-content: flex-end;
		font-weight: 500;

		&__btn{
			display: flex;
			align-items: center;
			padding: 3px;
		}

		&-icon{
			width: 32px;
			height: 32px;
			stroke: currentColor;
			margin-right: 15px;

			svg{
				width: 32px;
				height: 32px;
			}
		}

		p{
			margin-bottom: 0;
		}
	}

	&__links{
		display: flex;
		margin-left: -$gutter;
		width: calc(100% + #{full-gutter});
		font-weight: 700;

		> *{
			flex: 1 1 50%;
			flex-basis: calc(50% - #{full-gutter});
			margin-left: $gutter;
			margin-right: $gutter;
		}

		li:not(:last-child){
			margin-bottom: $gutter;
		}

		a{
			display: block;

			span{
				left: -3px;
				padding: 8px 3px;
			}
		}
	}

	&__bar{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px;
		flex-direction: column;

		@media (min-width: $sm) {
			flex-direction: row;
		}
	}

	&__escape{
		text-decoration: none;
		margin-top: 15px;

		&:hover {
			text-decoration: underline;
		}

		@media (min-width: $sm) {
			margin-top: 0;

		}
	}
}
