.l-cta {
    &--two {
        @media (min-width: $md) {
            display: flex;
            align-items: flex-start;
            padding-left: $gutter;
            padding-right: $gutter;
            justify-content: space-between;
        }

        > * {
            &:last-child {
                margin-top: 28px;

                .c-cta__content {
                    transition-delay: 175ms;
                }
            }
        }
    }

    &--three {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: (453px + $full-gutter) * 3;
        width: 100%;
        margin: 0 auto;

        // @media (min-width: unit(((453px + $full-gutter) * 3) / 16, em)){
        //     flex-wrap: nowrap;
        // }

        .c-cta {
            display: flex;
            justify-content: center;

            @media (min-width: unit(1440 / 16, em)) {
                display: inline-flex;
            }
        }

        > * {
            flex: 1 1 (300px - $full-gutter);
            width: (300px - $full-gutter);
            margin-left: $gutter;
            margin-right: $gutter;

            @media (min-width: $xs) {
                flex-basis: 453px - $full-gutter;
                width: 453px - $full-gutter;
            }

            @media (min-width: unit(((453px - $full-gutter) * 2) / 16, em)) {
                flex-grow: 0;
            }

            @media (min-width: unit(((453px + $full-gutter) * 3) / 16, em)) {
                flex-grow: 1;
            }

            &:not(:last-child) {
                margin-bottom: $full-gutter;

                @media (min-width: unit(((445px + $full-gutter) * 3) / 16, em)) {
                    margin-bottom: 0;
                }
            }

            &:nth-child(1) .c-cta__content,
            &:nth-child(1) .c-cta__overlay {
                transition-delay: 450ms;
            }

            &:nth-child(2) .c-cta__content,
            &:nth-child(2) .c-cta__overlay {
                transition-delay: 450ms + 225ms;
            }

            &:nth-child(3) .c-cta__content,
            &:nth-child(3) .c-cta__overlay {
                transition-delay: 450ms + 225ms * 2;
            }
        }
    }

    &--grid {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: (453px + $full-gutter) * 3;
        width: 100%;
        margin: 0 auto;

        .c-cta {
            display: flex;
            justify-content: center;

            @media (min-width: unit(1440 / 16, em)) {
                display: inline-flex;
            }
        }

        > * {
            flex: 1 1 (300px - $full-gutter);
            width: (300px - $full-gutter);
            margin: $gutter;

            @media (min-width: $xs) {
                flex-basis: 453px - $full-gutter;
                width: 453px - $full-gutter;
            }

            @media (min-width: unit(((453px - $full-gutter) * 2) / 16, em)) {
                flex-grow: 0;
            }
        }
    }

    &__button {
        padding-top: 30px;
        flex: 1 1 100%;
        text-align: center;
        margin-top: 0;
    }

    &--grid &__button {
        padding-top: 15px;
    }
}
