.c-featured-echo {
    padding-top: 60px;
    padding-bottom: 40px;
    overflow: hidden;

    @media (min-width: $sm) {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    &__image {
        position: relative;
        margin-bottom: 60px;

        @media (min-width: $sm) {
            margin-bottom: 0;
        }

        &:before {
            content: "";
            position: absolute;
            top: -20px;
            bottom: -20px;
            right: 50%;
            width: 50vw;
            background-color: $primary-dark;

            @media (min-width: $sm) {
                right: 25%;
                top: -80px;
                bottom: -80px;
            }
        }
    }

    &__image-wrap {
        position: relative;
        z-index: 1;
        min-width: 190px;
        margin: 0 auto;
        width: 60%;

        @media (min-width: $sm) {
            max-width: none;
            width: 100%;
        }

        &:before {
            content: "";
            display: block;
            padding-bottom: ratio(375, 537);
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            margin-bottom: 0;
        }
    }

    &__content {
        text-align: center;

        @media (min-width: $sm) {
            text-align: left;
        }

        p {
            display: none;

            @media (min-width: $sm) {
                display: block;
            }
        }

        .o-btn {
            margin-top: 0;

            @media (min-width: $sm) {
                margin-top: 30px;
            }
        }
    }
}
