.l-content{
    &--headers-left{
        @media (min-width: $md){
            display: flex;
        }

        > *{
            margin-left: $gutter;
            margin-right: $gutter;

            &:not(:last-child){
                margin-bottom: $gutter;
            }

            @media (min-width: $md){
                &:not(:last-child){
                    margin-bottom: 0;
                }

                &:first-child{
                    flex: 1 1 $threeColSpace;
                    flex-basis: calc(#{$threeColSpace} - #{$full-gutter});
                    padding-right: $oneColSpace;
                    width: calc(#{$threeColSpace} - #{$full-gutter});
                }

                &:last-child{
                    flex: 1 1 $eightColSpace;
                    flex-basis: calc(#{$eightColSpace} - #{$full-gutter});
                    width: calc(#{$eightColSpace} - #{$full-gutter});
                }
            }
        }
    }

    &--pullin-n-left-content{
        @media (min-width: $md){
            display: flex;
        }

        > *{
            margin-left: $gutter;
            margin-right: $gutter;

            &:not(:last-child){
                margin-bottom: $full-gutter;
            }

            @media (min-width: $md){
                &:not(:last-child){
                    margin-bottom: 0;
                }

                &:first-child{
                    flex: 1 1 $sevenColSpace;
                    flex-basis: calc(#{$sevenColSpace} - #{$full-gutter});
                    width: calc(#{$sevenColSpace} - #{$full-gutter});
                    padding-left: $twoColSpace;
                    padding-right: $oneColSpace;
                }

                &:last-child{
                    flex: 1 1 $twoColSpace;
                    flex-basis: calc(#{$twoColSpace} - #{$full-gutter});
                    width: calc(#{$twoColSpace} - #{$full-gutter});
                }
            }
        }
    }

    &--offset-left{
        @media (min-width: $md){
            padding-left: calc(#{$twoColSpace} + 5px);
        }
    }

    &--thirds,
    &--thirds-reverse{
        @media (min-width: $md){
            display: flex;
        }

        > *{
            margin-left: $gutter;
            margin-right: $gutter;

            &:not(:last-child){
                margin-bottom: $full-gutter;
            }

            @media (min-width: $md){
                &:not(:last-child){
                    margin-bottom: 0;
                }

                &:first-child,
                &:nth-child(2){
                    flex: 1 1 $fiveColSpace;
                    flex-basis: calc(#{$fiveColSpace} - #{$full-gutter});
                    width: calc(#{$fiveColSpace} - #{$full-gutter});
                    padding-right: 30px;
                }

                &:last-child{
                    flex: 1 1 $twoColSpace;
                    flex-basis: calc(#{$twoColSpace} - #{$full-gutter});
                    width: calc(#{$twoColSpace} - #{$full-gutter});
                }
            }
        }
    }

    &--thirds,
    &--thirds-reverse{
        > *{
            &:nth-child(1){
                z-index: 2;
            }

            &:nth-child(2),
            &:nth-child(3){
                opacity: 0;
                transform: translateY(20px);
                transition: opacity $userScrollIn $easeOut 300ms, transform $userScrollIn $easeOut 300ms;
            }

            &:nth-child(3){
                transition: opacity $userScrollIn $easeOut 450ms, transform $userScrollIn $easeOut 450ms;
            }
        }

        &.scroll-active > *{
            opacity: 1;
            transform: none;
        }
    }

    &--thirds-reverse{
        flex-direction: row-reverse;

        > *{
            @media (min-width: $md){
                &:first-child,
                &:nth-child(2){
                    padding-right: 0;
                    padding-left: 30px;
                }
            }
        }
    }

    &--tall-images{
        @media (min-width: $md){
            display: flex;
        }

        > *{
            margin-left: $gutter;
            margin-right: $gutter;

            &:not(:last-child){
                margin-bottom: $full-gutter;
            }

            @media (min-width: $md){
                &:not(:last-child){
                    margin-bottom: 0;
                }

                &:first-child{
                    flex: 1 1 $sixColSpace;
                    flex-basis: calc(#{$sixColSpace} - #{$full-gutter});
                    width: calc(#{$sixColSpace} - #{$full-gutter});
                }

                &:last-child{
                    flex: 1 1 $fiveColSpace;
                    flex-basis: calc(#{$fiveColSpace} - #{$full-gutter});
                    width: calc(#{$fiveColSpace} - #{$full-gutter});
                    padding-left: 50px;
                }
            }
        }
    }

    &--news-content{
        @media (min-width: $md){
            display: flex;
        }

        > *{
            margin-left: $gutter;
            margin-right: $gutter;

            &:not(:last-child){
                margin-bottom: $full-gutter;
            }

            @media (min-width: $md){
                &:not(:last-child){
                    margin-bottom: 0;
                }

                &:first-child{
                    flex: 1 1 $fourColSpace;
                    flex-basis: calc(#{$fourColSpace} - #{$full-gutter});
                    width: calc(#{$fourColSpace} - #{$full-gutter});
                }

                &:last-child{
                    flex: 1 1 $eightColSpace;
                    flex-basis: calc(#{$eightColSpace} - #{$full-gutter});
                    width: calc(#{$eightColSpace} - #{$full-gutter});
                }
            }
        }
    }

    &--news-content-bottom{
        align-self: flex-end;
        order: 1;

        @media (min-width: $md){
            order: 0;
        }
    }

    &--property{
        @media (min-width: $md){
            display: flex;
        }

        > *{
            margin-left: $gutter;
            margin-right: $gutter;

            &:last-child{
                padding-top: 15px;

                @media (min-width: $md){
                    padding: 0;
                }
            }

            &:not(:last-child){
                margin-bottom: $full-gutter;
            }

            @media (min-width: $md){
                &:not(:last-child){
                    margin-bottom: 0;
                }

                &:first-child{
                    flex: 1 1 $eightColSpace;
                    flex-basis: calc(#{$eightColSpace} - #{$full-gutter});
                    width: calc(#{$eightColSpace} - #{$full-gutter});
                    padding-right: $oneColSpace;
                }

                &:last-child{
                    flex: 1 1 $fourColSpace;
                    flex-basis: calc(#{$fourColSpace} - #{$full-gutter});
                    width: calc(#{$fourColSpace} - #{$full-gutter});
                }
            }
        }
    }
}

@supports (display: grid){
    .l-content{
        &--headers-left{
            display: grid;
            grid-template-columns: 1fr;
            grid-template-areas: "headers" "content";
            grid-gap: $gutter;
            padding-left: $gutter;
            padding-right: $gutter;

            @media (min-width: $md){
                grid-template-columns: 3fr 1fr 8fr;
                grid-template-areas: "headers ... content";
            }

            > *{
                margin: 0;

                &:not(:last-child){
                    margin-bottom: 0;
                }

                &:first-child{
                    grid-area: headers;
                    padding-right: 0;
                    width: auto;
                }

                &:last-child{
                    grid-area: content;
                    width: auto;
                }
            }

            &__no-properties {
                background-color: #eee;
                margin-bottom: 30px;
                margin-right: 20px;
                position: relative;
                padding: 30px 25px;

                .h3 {
                    margin-bottom: 10px;
                }

                p {
                    margin-bottom: 0;
                    margin-right: 20px;
                }

                .o-btn {
                    margin-top: 0;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(50%, -50%);
                }
            }
        }

        &--pullin-n-left-content{
            display: grid;
            grid-template-columns: 1fr;
            grid-template-areas: "content" "left";
            grid-gap: $full-gutter;
            padding-left: $gutter;
            padding-right: $gutter;

            @media (min-width: $md){
                grid-template-columns: 2fr 7fr 1fr 2fr;
                grid-template-areas: "... content ... left";
            }

            > *{
                margin: 0;

                &:not(:last-child){
                    margin-bottom: 0;
                }

                &:first-child{
                    grid-area: content;
                    padding: 0;
                    width: auto;
                }

                &:last-child{
                    grid-area: left;
                    width: auto;
                }
            }
        }

        &--thirds,
        &--thirds-reverse{
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: $full-gutter;
            padding-left: $gutter;
            padding-right: $gutter;

            @media (min-width: $md){
                grid-template-columns: 5fr 5fr 2fr;
            }

            > *{
                margin: 0;

                &:not(:last-child){
                    margin-bottom: 0;
                }

                &:first-child,
                &:nth-child(2),
                &:last-child{
                    width: auto;
                }

                &:last-child{
                    img{
                        width: 100%;
                        max-width: 180px;
                    }
                }
            }
        }

        &--thirds-reverse{
            @media (min-width: $md){
                grid-template-columns: 2fr 5fr 5fr;

                > *{
                    order: 1;

                    &:first-child{
                        order: 2;
                    }

                    &:last-child{
                        order: 0;
                    }
                }
            }
        }

        &--tall-images{
            display: grid;
            grid-template-columns: 1fr;
            grid-template-areas: "tall" "skinny";
            grid-gap: $full-gutter;
            padding-left: $gutter;
            padding-right: $gutter;

            @media (min-width: $md){
                grid-template-columns: 6fr 1fr 5fr;
                grid-template-areas: "tall ... skinny";
            }

            > *{
                &:not(:last-child){
                    margin-bottom: 0;
                }

                &:first-child{
                    grid-area: tall;
                    width: auto;

                    @media (min-width: $md){
                        padding-right: 30px;
                    }
                }

                &:last-child{
                    grid-area: skinny;
                    width: auto;
                }
            }
        }

        &--news-content{
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: $full-gutter;
            padding-left: $gutter;
            padding-right: $gutter;

            @media (min-width: $md){
                grid-template-columns: 4fr 8fr;
            }

            > *{
                margin: 0;

                &:not(:last-child){
                    margin-bottom: 0;
                }

                &:first-child,
                &:last-child{
                    width: auto;
                }
            }
        }

        &--news-content-bottom{
            align-self: flex-end;
        }

        &--property{
            display: grid;
            grid-template-columns: 1fr;
            grid-template-areas: "left" "right";
            grid-gap: $full-gutter;
            padding-left: $gutter;
            padding-right: $gutter;

            @media (min-width: $md){
                grid-template-columns: 8fr 1fr 4fr;
                grid-template-areas: "left ... right";
            }

            > *{
                margin: 0;

                &:not(:last-child){
                    margin-bottom: 0;
                }

                &:first-child{
                    grid-area: left;
                    padding-right: 0;
                    width: auto;
                }

                &:last-child{
                    grid-area: right;
                    width: auto;
                }
            }
        }


    }
}
