.o-links a,
.o-link,
.o-links--inner-text a span,
.typography a:not(.o-btn){
    position: relative;
    display: inline-block;
    outline: none;
    text-decoration: none;
    color: currentColor;
    overflow: hidden;
    vertical-align: bottom;

    @media (min-width: $sm){
        white-space: nowrap;
    }

    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $primary;
        z-index: -1;
        transform: translateY(102%);
        transition: transform $userInactive $easeOut;
        backface-visibility: hidden;
        pointer-events: none;
    }
}

.o-links.o-links--grey a,
.o-link.o-link--grey,
.o-links--inner-text.o-links--grey a span{
    &:before{
        background-color: $grey;
    }
}

.o-links a,
.o-link,
.typography a:not(.o-btn){
    &:hover,
    &:focus{
        color: $grey;
    }

    &:hover:before,
    &:focus:before{
        transition-duration: $userActive;
        transform: none;
    }
}

.o-links.o-links--grey a,
.o-link.o-link--grey{
    &:hover,
    &:focus{
        color: $primary;
    }
}

.o-links--inner-text a{
    &:hover span:before,
    &:focus span:before{
        transition-duration: $userActive;
        transform: none;
    }
}

.o-links--underlined a,
.o-link--underlined,
.typography a:not(.o-btn){
    &:after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 3px;
        width: 100%;
        height: 1px;
        background-color: $primary;
        z-index: -1;
        backface-visibility: hidden;
        pointer-events: none;
    }
}

.o-links--underlined.o-links--grey a,
.o-link--underlined.o-link--grey{
    &:after{
        background-color: $grey;
    }
}
